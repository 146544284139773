// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default () => {
  window.dataLayer = window.dataLayer || []

  window.onRecaptchaJSLibraryLoad = () => {
    if (typeof window.grecaptcha !== `undefined`) {
      window.grecaptcha
        .execute(`6LeTq8QUAAAAALzMbYpu2HUrXNNpnNqic-v8JjEl`)
        .then(function (token) {
          if (
            document.getElementById(`mauticform_input_trialrequest_recaptcha`)
          ) {
            document.getElementById(
              `mauticform_input_trialrequest_recaptcha`
            ).value = token
          }
        })
    }
  }

  function IDGenerator() {
    this.length = 77
    this.timestamp = +new Date()

    const _getRandomInt = function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    this.generate = function () {
      const ts = this.timestamp.toString()
      const parts = ts.split(``).reverse()
      let id = ``

      for (let i = 0; i < this.length; ++i) {
        const index = _getRandomInt(0, parts.length - 1)
        id += parts[index]
      }
      return id
    }
  }

  if (typeof window.MauticFormCallback == `undefined`) {
    window.MauticFormCallback = {}
  }

  window.MauticFormCallback[`trialrequest`] = {
    onValidateEnd: function (formValid) {
      // before form submit
      window.generator = new IDGenerator()
      window.uniqid = window.generator.generate()
      document.getElementById(
        `mauticform_input_trialrequest_identifier`
      ).value = window.uniqid
      // validate reCaptcha
      if (typeof grecaptcha == `undefined`) {
        const head = document.getElementsByTagName(`head`)[0]
        const script = document.createElement(`script`)
        script.type = `text/javascript`
        script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaJSLibraryLoad&render=6LeTq8QUAAAAALzMbYpu2HUrXNNpnNqic-v8JjEl`
        head.appendChild(script)
      } else {
        window.grecaptcha
          .execute(`6LeTq8QUAAAAALzMbYpu2HUrXNNpnNqic-v8JjEl`)
          .then(function (token) {
            if (
              document.getElementById(`mauticform_input_trialrequest_recaptcha`)
            ) {
              document.getElementById(
                `mauticform_input_trialrequest_recaptcha`
              ).value = token
            }
          })
      }
    },
    onResponse: function (response) {
      // after form submit
      if (
        typeof window.MauticFormCallback[`trialrequest`][`onResponseRun`] ==
        `undefined`
      ) {
        window.MauticFormCallback[`trialrequest`][`onResponseRun`] = 1

        const langtag = document.documentElement.lang
        let trialtype = `-`
        if (
          document.getElementById(
            `mauticform_input_trialrequest_forced_project_id_2`
          ) &&
          document.getElementById(
            `mauticform_input_trialrequest_forced_project_id_2`
          ).checked
        ) {
          trialtype = `Full demo`
        }
        if (
          document.getElementById(
            `mauticform_input_trialrequest_forced_project_id_1`
          ) &&
          document.getElementById(
            `mauticform_input_trialrequest_forced_project_id_1`
          ).checked
        ) {
          trialtype = `Lite demo`
        }
        window.dataLayer.push({
          event: `gaEvent`,
          gaEventData: {
            eventCategory: `Trial`,
            eventAction: `01-Trial-form-submitted`,
            eventLabel: trialtype,
            nonInteraction: false,
          },
          eventCallback: function () {
            window.dataLayer.push({
              event: `gaVirtualPageview`,
              gaVirtualPageviewUrl: `/virtual/Trial/01-Trial-form-submitted`,
              gaVirtualPageviewTitle: `Virtual Trial/01-Trial-form-submitted`,
              eventCallback: function () {
                dataLayer.push({ gaEventData: undefined })
                // redirect to lobby
                setTimeout(() => {
                  window.location.replace(
                    `https://lobby.easysoftware.com/trial/` +
                      window.uniqid +
                      `?brand=ep.cz&lang=` +
                      langtag
                  )
                }, 500)
              },
            })
          },
        })
        // redirect to lobby
        setTimeout(() => {
          window.location.replace(
            `https://lobby.easysoftware.com/trial/` +
              window.uniqid +
              `?brand=ep.cz&lang=` +
              langtag
          )
        }, 3000)
      }
    },
  }
}
